import React from "react";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";

//       <!--
//   This example requires Tailwind CSS v2.0+

//   This example requires some changes to your config:

//   ```
//   // tailwind.config.js
//   module.exports = {
//     // ...
//     plugins: [
//       // ...
//       require('@tailwindcss/typography'),
//     ]
//   }
//   ```
// -->

function PHPage(props) {
  return (
    <React.Fragment>
      <Helmet
        title="Precision Hydration Success Story - Gatsby Storefront for Shopify Headless
              Commerce"
      >
        <meta
          name="description"
          content="With Gatsby Storefront for Shopify Headless
              Commerce Precision Hydration achieved exceptional user
                  experience, SEO rankings, and growth of conversion rates."
        />
      </Helmet>

      <div className="py-6 sm:py-16 bg-gray-50 overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-none">
            {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
            <nav className="flex" aria-label="Breadcrumb">
              <ol className="flex items-center space-x-4">
                <li>
                  <div>
                    <Link to="/" className="text-gray-400 hover:text-gray-500">
                      {/* <!-- Heroicon name: solid/home --> */}
                      <svg
                        className="flex-shrink-0 -mt-0.5 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                      </svg>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg
                      className="flex-shrink-0 h-5 w-5 text-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>

                    <span className="ml-4 text-base text-blue-600 font-semibold tracking-wide uppercase">
                      Success Story
                    </span>
                  </div>
                </li>
              </ol>
            </nav>

            <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Gatsby Storefront for leading electrolyte supplier's Headless
              Commerce
            </h1>
          </div>
          <div className="relative z-9 text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
            <p className="text-lg text-gray-500">
              Precision Hydration, a UK-based electrolyte supplements supplier
              for world-famous professional athletes and teams, has the vision
              to scale its business globally.
            </p>
          </div>
          <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
            <div className="relative z-9">
              <div className="prose prose-blue text-gray-500 mx-auto lg:max-w-none">
                <p>
                  The ambition required a first-class e-commerce solution that
                  supports working with different currencies, checkout methods,
                  supply, and delivery chains, as well as providing the best
                  user experience for their valuable customers.
                </p>
                <p>
                  Working very close with Precision Hydration's management and
                  marketing teams, we created a{" "}
                  <b>international Headless Commerce</b> solution that unites
                  five regional stores under one international storefront.
                </p>
                <p>
                  Headless Commerce solution allowed to seamlessly integrate the
                  data used by Precision Hydration's marketing team from{" "}
                  <b>Shopify</b>, <b>Contentful</b>, <b>Google Docs</b>,{" "}
                  <b>Klavio</b>, and other sources.
                </p>
                <p>
                  With Gatsby Storefront, we achieved exceptional user
                  experience, SEO rankings, and growth of conversion rates.
                </p>
                <div className="mt-8 ">
                  <dl className="-mx-8 -mt-8 flex flex-col sm:flex-row">
                    <div className="flex flex-col px-8 pt-8">
                      <dt className="order-2 text-base font-medium text-center text-gray-500">
                        Shopify Stores
                      </dt>
                      <dd className="order-1 text-2xl font-extrabold text-center text-blue-600 sm:text-3xl">
                        5
                      </dd>
                    </div>
                    <div className="flex flex-col px-8 pt-8">
                      <dt className="order-2 text-base font-medium text-center text-gray-500">
                        Single domain storefront
                      </dt>
                      <dd className="order-1 text-2xl font-extrabold text-center text-blue-600 sm:text-3xl">
                        1
                      </dd>
                    </div>
                    <div className="flex flex-col px-8 pt-8">
                      <dt className="order-2 text-base font-medium text-center text-gray-500">
                        SEO ranking
                      </dt>
                      <dd className="order-1 text-2xl font-extrabold  text-center text-blue-600 sm:text-3xl">
                        100%
                      </dd>
                    </div>
                    <div className="flex flex-col px-8 pt-8">
                      <dt className="order-2 text-base font-medium text-center text-gray-500">
                        Conversions
                      </dt>
                      <dd className="order-1 text-2xl font-extrabold text-center text-blue-600 sm:text-3xl">
                        x2
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
            <div className="mt-12 relative text-base max-w-prose mx-auto lg:mt-0 lg:max-w-none">
              <svg
                className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"
                />
              </svg>

              <div>
                <a
                  href="https://precisionhydration.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img
                    className="relative"
                    fixed={props.data.file.childImageSharp.fixed}
                    alt="Gatsby JS & Shopify for Headless Commerce"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="max-w-7xl mx-auto pt-12 px-4 sm:px-6  lg:px-8 l">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">Ready to dive in?</span>
              <span className="block text-blue-600">
                Start with Gatsby Storefront.
              </span>
            </h2>
            <div className="mt-8 flex">
              <div className="inline-flex rounded-md shadow">
                <a
                  href="/request-a-demo/"
                  className="inline-flex items-center justify-center px-5 py-3 border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-800 to-blue-600 hover:to-blue-500"
                >
                  Request a Demo
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    file(relativePath: { eq: "ph-gatsby-shopify.png" }) {
      childImageSharp {
        fixed(width: 962) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

export default PHPage;
